<template>
    <div class="px-3 py-2">
        <b-sidebar aria-labelledby="sidebar-no-header-title" width="700px" no-header id="emp_deducts" backdrop style="direction:ltr" right title="اضافة مصروف دوري" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" :style="`direction:rtl`">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span style="font-size:.8em;">{{$parent.lang.emp_deducts}}</span>
                    </div>
                    <div @click="hide" id="dedhideExp" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{$parent.lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" :style="`direction:`+$parent.lang.dir">
                    <v-form ref="apform">
                        <v-row>
                            <v-col cols="12" md="4" sm="12">
                                <label>{{$parent.lang.deducts_amount}}</label>
                                <b-form-input class="inborder"
                                    :label="$parent.lang.deducts_amount"
                                    v-model="amount"
                                    :rules="fieldRules"
                                    >{{ amount }}</b-form-input>
                            </v-col>
                            <v-col cols="12" md="8" sm="12">
                                <label>{{$parent.lang.deducts_resone}}</label>
                                <b-form-input class="inborder"
                                    :label="$parent.lang.deducts_resone"
                                    v-model="reward_resone"
                                    :rules="fieldRules"
                                    >{{ reward_resone }}</b-form-input>
                            </v-col>
                            
                        </v-row>
                    </v-form>
                </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" variant="success" @click="addit()" class="ma-2" style="width:100px;">{{$parent.lang.add}}</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    data() {
        return {
            empid: 0,
            amount: '',
            reward_resone: '',
            fieldRules: [
                v => !!v || this.$parent.lang.required_field
            ]
        }
    },
    methods: {
        addit() {
            let vald = true;
            let message = '';
            if(this.amount == '' || this.amount <= 0){
                vald = false;
                message = this.$parent.lang.mor_than_zero;
            }
            else if(this.reward_resone == ''){
                vald = false;
                message = this.$parent.lang.required_field;
            }
            if(!vald){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type","addReward");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[empid]",this.empid);
            post.append("data[amount]",this.amount);
            post.append("data[reward_resone]",this.reward_resone);
            post.append("data[type]",2);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                // // console.log(response.data);
                this.amount = '',
                this.reward_resone = "";   
                this.$parent.getEmployees();             
                document.getElementById('dhideExp').click();

            })
        }
    },
}
</script>